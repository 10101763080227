// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div v-if="!userHasKeysToAccessS3Provider">
    <a-row style="background-color: white; font-size: 14px; padding: 10px;">
      In order to use the S3 Dashboard, you must create a key pair that contains the following permission:
    </a-row>
    <a-row style="background-color: white; font-size: 14px; padding: 10px;">
      <b>RGWCloudStackIntegration</b>
    </a-row>
    <a-row style="background-color: white; font-size: 14px; padding: 10px;">
      <a-button type="primary" @click="generateKeyPair()">
        Click here to generate the necessary key pair
      </a-button>
    </a-row>
  </div>
  <div v-else>
    <a-row :gutter="12" v-if="objects === null">
      <a-col :md="24">
        <a-card class="breadcrumb-card">
          <a-col :md="24" style="display: flex">
            <breadcrumb style="padding-top: 6px; padding-left: 8px" />
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="fetchData()" >
              <template #icon><reload-outlined /></template>
              {{ $t('label.refresh') }}
            </a-button>
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="showCreateBucketForm = true" >
              <template #icon><plus-outlined /></template>
              {{ 'Create bucket' }}
            </a-button>
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="showAddBucketForm = true" >
              <template #icon><plus-outlined /></template>
              {{ 'Add shared bucket' }}
            </a-button>
          </a-col>
        </a-card>
      </a-col>
      <a-col :md="24">
        <div class="config-row-element">
          <a-table
            size="small"
            :columns="bucketColumns"
            :dataSource="buckets"
            :rowKey="record => record.Name"
            :pagination="false">
            <template #name="{ record }">
              <router-link @onclick="fetchData" :to="{ path: '/s3/' + record.Name }">
                {{ record.Name }}
              </router-link>
            </template>
            <template #actions="{ record }">
              <tooltip-button
                :tooltip="$t('label.delete')"
                type="primary"
                :danger="true"
                button-class="mr-2"
                icon="delete-outlined"
                @onClick="deleteBucket(record.Name)" />
              <tooltip-button
                style="margin-right: 5px"
                :tooltip="'Edit Bucket Policy'"
                :danger="false"
                icon="edit-outlined"
                @onClick="showPolicyModal(record.Name)" />
            </template>
          </a-table>
        </div>
      </a-col>
      <a-col>
        <a-modal
          v-if="showCreateBucketForm"
          :visible="showCreateBucketForm"
          :title="'Create Bucket'"
          :closable="true"
          :maskClosable="false"
          :footer="null"
          :cancelText="$t('label.cancel')"
          @cancel="showCreateBucketForm = false"
          centered
          ref="importModal"
          width="auto">
          <div>
            <a-form
              :ref="formRef"
              :model="form"
              :rules="rules"
              @finish="handleSubmit"
              layout="vertical">
              <a-form-item name="name" ref="name">
                <template #label>
                  <tooltip-label :title="$t('label.name')" :tooltip="'Name'"/>
                </template>
                <a-input
                  v-model:value="createBucketData.name"
                  :placeholder="'Bucket name'"
                  ref="name"
                  v-focus="true" />
              </a-form-item>
              <div :span="24" class="action-button">
                <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
                <a-button :loading="loading" type="primary" @click="createBucket">{{ $t('label.ok') }}</a-button>
              </div>
            </a-form>
          </div>
        </a-modal>
      </a-col>
      <a-col>
        <a-modal
          v-if="showAddBucketForm"
          :visible="showAddBucketForm"
          :title="'Add Bucket'"
          :closable="true"
          :maskClosable="false"
          :footer="null"
          :cancelText="$t('label.cancel')"
          @cancel="showAddBucketForm = false"
          centered
          ref="importModal"
          width="auto">
          <div>
            <a-form
              :ref="formRef"
              :model="form"
              :rules="rules"
              @finish="handleSubmit"
              layout="vertical">
              <a-form-item name="name" ref="name">
                <template #label>
                  <tooltip-label :title="$t('label.name')" :tooltip="'Name'"/>
                </template>
                <a-input
                  v-model:value="addBucketData.name"
                  :placeholder="'Bucket name'"
                  ref="name"
                  v-focus="true" />
              </a-form-item>
              <div :span="24" class="action-button">
                <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
                <a-button :loading="loading" type="primary" @click="addBucket">{{ $t('label.ok') }}</a-button>
              </div>
            </a-form>
          </div>
        </a-modal>
      </a-col>
      <a-col>
        <a-modal
          v-if="showCreatePolicyForm"
          :visible="showCreatePolicyForm"
          :title="'Edit Policy'"
          :closable="true"
          :maskClosable="false"
          :footer="null"
          :cancelText="$t('label.cancel')"
          @cancel="showCreatePolicyForm = false"
          centered
          ref="importModal"
          width="auto">
          <div>
            <a-form
              :ref="formRef"
              :model="form"
              :rules="rules"
              @finish="handleSubmit"
              layout="vertical">
              <a-form-item name="name" ref="name">
                <template #label>
                  <tooltip-label :title="'Policy'" :tooltip="'Policy'"/>
                </template>
                <a-textarea
                  style="width: 500px;resize: both;"
                  v-model:value="createPolicyData.policy"
                  :placeholder="'Policy'"
                  ref="name"
                  v-focus="true" />
              </a-form-item>
              <div :span="24" class="action-button">
                <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
                <a-button :loading="loading" type="primary" @click="createPolicy">{{ $t('label.ok') }}</a-button>
              </div>
            </a-form>
          </div>
        </a-modal>
      </a-col>
    </a-row>
    <a-row :gutter="12" v-if="objects !== null">
      <a-col :md="24">
        <a-card class="breadcrumb-card">
          <a-col :md="24" style="display: flex">
            <breadcrumb style="padding-top: 6px; padding-left: 8px" />
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="fetchData()" >
              <template #icon><reload-outlined /></template>
              {{ $t('label.refresh') }}
            </a-button>
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="showCreateObjectForm = true" >
              <template #icon><plus-outlined /></template>
              {{ 'Upload object' }}
            </a-button>
            <a-button
              style="margin-left: 12px; margin-top: 4px"
              :loading="viewLoading"
              size="small"
              shape="round"
              @click="showCreateFolderForm = true" >
              <template #icon><plus-outlined /></template>
              {{ 'Create folder' }}
            </a-button>
          </a-col>
        </a-card>
      </a-col>
      <a-col :md="24">
        <div class="config-row-element">
          <a-table
            size="small"
            :columns="objectColumns"
            :dataSource="objects"
            :rowKey="record => record.Key"
            :pagination="false">
            <template #name="{ record }">
              <a @click="downloadObject(record.Key)" v-if="record.Size > 0">
                {{ record.Label }}
              </a>
              <router-link v-if="record.Folder" :to="{ path: '/s3/' + currentBucket + '/' + record.Key }">
                {{ record.Label }}
              </router-link>
              <span v-if="record.Size === 0 && !record.Folder">
                {{ record.Label }}
              </span>
            </template>
            <template #size="{ record }">
              {{ this.humanReadable(record.Size) }}
            </template>
            <template #actions="{ record }">
              <tooltip-button
                v-if="!record.Folder"
                :tooltip="$t('label.delete')"
                type="primary"
                :danger="true"
                icon="delete-outlined"
                @onClick="deleteObject(record.Key)" />
            </template>
          </a-table>
        </div>
      </a-col>
      <a-col>
        <a-modal
          v-if="showCreateObjectForm"
          :visible="showCreateObjectForm"
          :title="'Create Object'"
          :closable="true"
          :maskClosable="false"
          :footer="null"
          :cancelText="$t('label.cancel')"
          @cancel="showCreateObjectForm = false"
          centered
          ref="importModal"
          width="auto">
          <div>
            <a-form
              :ref="formRef"
              :model="form"
              :rules="rules"
              @finish="handleSubmit"
              layout="vertical">
              <a-form-item name="name" ref="name">
                <template #label>
                  <tooltip-label :title="$t('label.name')" :tooltip="'Name'"/>
                </template>
                <a-input
                  v-model:value="createObjectData.name"
                  :placeholder="'Object name'"
                  ref="name"
                  v-focus="true" />
              </a-form-item>
              <a-form-item name="file" ref="file">
                <template #label>
                  <tooltip-label :title="'Upload File'" :tooltip="'Upload File'"/>
                </template>
                <a-upload-dragger
                  :multiple="false"
                  :fileList="fileList"
                  :remove="handleRemove"
                  :beforeUpload="beforeUpload"
                  @change="handleChange"
                  v-model:value="createObjectData.file">
                  <p class="ant-upload-drag-icon">
                    <cloud-upload-outlined />
                  </p>
                  <p class="ant-upload-text" v-if="fileList.length === 0">
                    Upload File
                  </p>
                </a-upload-dragger>
              </a-form-item>
              <div :span="24" class="action-button">
                <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
                <a-button :loading="loading" type="primary" @click="createObject">{{ $t('label.ok') }}</a-button>
              </div>
            </a-form>
          </div>
        </a-modal>
      </a-col>
      <a-col>
        <a-modal
          v-if="showCreateFolderForm"
          :visible="showCreateFolderForm"
          :title="'Create Object'"
          :closable="true"
          :maskClosable="false"
          :footer="null"
          :cancelText="$t('label.cancel')"
          @cancel="showCreateFolderForm = false"
          centered
          ref="importModal"
          width="auto">
          <div>
            <a-form
              :ref="formRef"
              :model="form"
              :rules="rules"
              @finish="handleSubmit"
              layout="vertical">
              <a-form-item name="name" ref="name">
                <template #label>
                  <tooltip-label :title="$t('label.name')" :tooltip="'Name'"/>
                </template>
                <a-input
                  v-model:value="createFolderData.name"
                  :placeholder="'Folder name'"
                  ref="name"
                  v-focus="true" />
              </a-form-item>
              <div :span="24" class="action-button">
                <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
                <a-button :loading="loading" type="primary" @click="createFolder">{{ $t('label.ok') }}</a-button>
              </div>
            </a-form>
          </div>
        </a-modal>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { api } from '@/api'
import {
  S3Client, ListBucketsCommand, CreateBucketCommand, DeleteBucketCommand,
  ListObjectsCommand, DeleteObjectCommand, PutObjectCommand, GetObjectCommand,
  PutBucketPolicyCommand, GetBucketPolicyCommand
} from '@aws-sdk/client-s3'
import Breadcrumb from '@/components/widgets/PathingBreadcrumb.vue'
import TooltipLabel from '@/components/widgets/TooltipLabel.vue'
import TooltipButton from '@/components/widgets/TooltipButton.vue'
import Cookies from 'js-cookie'

export default {
  name: 'BucketTable',
  components: { TooltipButton, TooltipLabel, Breadcrumb },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      buckets: null,
      objects: null,
      extra_buckets: [],
      prefix: '',
      currentBucket: null,
      showAddBucketForm: false,
      showCreateBucketForm: false,
      showCreateObjectForm: false,
      showCreateFolderForm: false,
      showCreatePolicyForm: false,
      apiKey: null,
      createBucketData: {},
      createObjectData: {},
      createFolderData: {},
      createPolicyData: {},
      addBucketData: {},
      fileList: [],
      viewLoading: true,
      secretKey: null,
      s3Client: null,
      userHasKeysToAccessS3Provider: false,
      bucketColumns: [
        {
          title: this.$t('label.name'),
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.action'),
          slots: { customRender: 'actions' }
        }
      ],
      objectColumns: [
        {
          title: this.$t('label.name'),
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.size'),
          slots: { customRender: 'size' }
        },
        {
          title: this.$t('label.action'),
          slots: { customRender: 'actions' }
        }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    $route (to, from) {
      console.log('from, to, ', from, to)
      if (to.path.indexOf(':catchAll(.*)') >= 0) {
        console.log('back to root')
        this.$router.push({ path: '/s3' })
        return
      }
      this.fetchData()
    }
  },
  methods: {
    showPolicyModal (key) {
      this.createPolicyData.key = key
      this.s3Client.send(new GetBucketPolicyCommand({ Bucket: key })).then(res => {
        this.createPolicyData.policy = res.Policy || {}
        this.showCreatePolicyForm = true
      }).catch(err => {
        console.log(err)
        this.showCreatePolicyForm = true
      })
    },
    beforeUpload (file) {
      this.fileList = [file]
      this.createObjectData.file = file
      return false
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
      this.createObjectData.file = undefined
    },
    createClient () {
      const url = 'https://rgw.scclouds.com.br'
      console.log('Keys', this.apiKey, this.secretKey)
      return new S3Client({
        endpoint: url,
        region: 'us-east-1',
        forcePathStyle: true,
        credentials: {
          accessKeyId: this.apiKey,
          secretAccessKey: this.secretKey
        }
      })
    },
    closeAction () {
      this.showCreateBucketForm = false
      this.showCreateFolderForm = false
      this.showCreateObjectForm = false
      this.showCreatePolicyForm = false
      this.showAddBucketForm = false
    },
    humanReadable (size) {
      const kb = 1024
      const mb = kb * kb
      const gb = mb * kb
      if (size > gb) {
        return `${size / gb | 0} GiB`
      }
      if (size > mb) {
        return `${size / mb | 0} MiB`
      }
      if (size > kb) {
        return `${size / kb | 0} KiB`
      }
      return `${size} Bytes`
    },
    deleteBucket (bucketName) {
      this.s3Client.send(new DeleteBucketCommand({ Bucket: bucketName })).then(res => {
        this.$message.success({
          content: `Bucket ${bucketName} delete.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error deleting bucket ${bucketName}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.fetchData()
      })
    },
    createBucket () {
      this.s3Client.send(new CreateBucketCommand({ Bucket: this.createBucketData.name })).then(res => {
        this.$message.success({
          content: `Bucket ${this.createBucketData.name} created.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error creating bucket ${this.createBucketData.name}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.$emit('close-action')
        this.closeAction()
        this.fetchData()
      })
    },
    addBucket () {
      this.s3Client.send(new ListObjectsCommand({ Bucket: this.addBucketData.name })).then(res => {
        this.$message.success({
          content: `Bucket ${this.createBucketData.name} created.`,
          key: 'OK2',
          duration: 3
        })
        this.extra_buckets.push(this.addBucketData.name)
      }).catch(err => {
        this.$message.error({
          content: `Error adding shared bucket ${this.createBucketData.name}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.$emit('close-action')
        this.closeAction()
        this.fetchData()
      })
    },
    createObject () {
      this.s3Client.send(new PutObjectCommand({ Bucket: this.currentBucket, Key: this.prefix + this.createObjectData.name, Body: this.createObjectData.file })).then(res => {
        this.$message.success({
          content: `Object ${this.createObjectData.name} created.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error creating object ${this.createObjectData.name}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.$emit('close-action')
        this.closeAction()
        this.fetchData()
      })
    },
    createPolicy () {
      this.s3Client.send(new PutBucketPolicyCommand({ Bucket: this.createPolicyData.key, Policy: this.createPolicyData.policy })).then(res => {
        this.$message.success({
          content: `Policy for bucket ${this.createPolicyData.key} changed.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error defining bucket policy ${this.createPolicyData.key}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.$emit('close-action')
        this.closeAction()
        this.fetchData()
      })
    },
    createFolder () {
      this.s3Client.send(new PutObjectCommand({ Bucket: this.currentBucket, Key: this.prefix + this.createFolderData.name + '/' })).then(res => {
        this.$message.success({
          content: `Folder ${this.createFolderData.name} created.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error creating folder ${this.createFolderData.name}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.$emit('close-action')
        this.closeAction()
        this.fetchData()
      })
    },
    deleteObject (key) {
      this.s3Client.send(new DeleteObjectCommand({ Bucket: this.currentBucket, Key: key })).then(res => {
        this.$message.success({
          content: `Object ${key} deleted.`,
          key: 'OK2',
          duration: 3
        })
      }).catch(err => {
        this.$message.error({
          content: `Error deleting object ${key}: ${err}`,
          key: 'OK2',
          duration: 3
        })
      }).finally(() => {
        this.fetchData()
      })
    },
    generateKeyPair () {
      api('registerUserKeys', {
        name: 'RGWCloudStackIntegration',
        'rules[0].permission': 'allow',
        'rules[0].rule': 'RGWCloudStackIntegration',
        id: Cookies.get('userid')
      }).then(response => {
        const jobId = response.registeruserkeysresponse.jobid
        if (jobId) {
          this.$pollJob({
            jobId,
            title: 'Generating key pair',
            successMethod: () => {
              this.fetchData()
            }
          })
        }
      })
    },
    fetchData () {
      api('listUserKeys', { showpermissions: true }).then(apiKeys => {
        const paths = this.$route.path.split('/')
        if (paths.length > 2) {
          this.currentBucket = paths[2]
          let prefix = ''
          for (let i = 3; i < paths.length - 1; i++) {
            prefix += paths[i] + '/'
          }
          this.prefix = prefix
          console.log('Current bucket', this.currentBucket)
        } else {
          this.currentBucket = null
          this.objects = null
          this.prefix = ''
        }
        const apk = apiKeys?.listuserkeysresponse
        if (apk?.count > 0 && apk?.userapikey.find(pair => pair.permissions.find(permission => permission.permission === 'allow' && permission.rule === 'RGWCloudStackIntegration'))) {
          const { apikey, secretkey } = apk?.userapikey.find(pair => pair.permissions.find(permission => permission.permission === 'allow' && permission.rule === 'RGWCloudStackIntegration'))
          this.apiKey = apikey
          this.secretKey = secretkey
          this.s3Client = this.createClient()
          if (this.currentBucket) {
            this.fetchObjects()
          } else {
            this.fetchBuckets()
          }
          this.userHasKeysToAccessS3Provider = true
        } else {
          this.userHasKeysToAccessS3Provider = false
        }
      }).catch(err => {
        this.userHasKeysToAccessS3Provider = false
        this.$message.error({
          content: 'You must have at least 1 api key to use this dashboard' + err,
          key: 'OK2',
          duration: 3
        })
        throw err
      }).finally(() => {
        this.viewLoading = false
      })
    },
    downloadObject (key) {
      this.s3Client.send(new GetObjectCommand({ Key: key, Bucket: this.currentBucket })).then(resp => {
        const blobs = []
        const reader = resp.Body.getReader()
        const read = () => {
          reader.read().then(({ done, value }) => {
            if (done) {
              const blobUrl = window.URL.createObjectURL(new Blob(blobs, { type: 'application/octet-stream' }))
              const link = document.createElement('a')
              link.href = blobUrl
              link.setAttribute('download', key)
              document.body.appendChild(link)
              link.click()
              link.parentNode.removeChild(link)
              window.URL.revokeObjectURL(blobUrl)
            } else {
              blobs.push(value)
              read()
            }
          })
        }
        read()
      })
    },
    fetchBuckets () {
      this.s3Client.send(new ListBucketsCommand({})).then(resp => {
        const buckets = resp.Buckets
        buckets.push(...this.extra_buckets.map(x => ({ Name: x })))
        console.log('Buckets', buckets)
        this.buckets = buckets
      })
    },
    fetchObjects () {
      this.s3Client.send(new ListObjectsCommand({ Bucket: this.currentBucket, Delimiter: '/', Prefix: this.prefix })).then(resp => {
        console.log('Objects', resp)
        const objects = (resp.Contents || []).map(x => ({ Size: x.Size, Key: x.Key, Label: x.Key.split('/').filter(r => r !== '').pop() || '.', Folder: false }))
        for (const folder of resp.CommonPrefixes || []) {
          objects.push({ Size: 0, Key: folder.Prefix, Folder: true, Label: folder.Prefix.split('/').filter(r => r !== '').pop() || '.' })
        }
        objects.sort((a, b) => (a.Key < b.Key) ? -1 : (a.Key > b.Key) ? 1 : 0)
        this.objects = objects
      }).catch(err => {
        this.$message.error({
          content: `Cannot access bucket ${this.currentBucket}`,
          key: 'OK2',
          duration: 3
        })
        this.currentBucket = null
        this.objects = null
        throw err
      })
    }
  }
}
</script>
